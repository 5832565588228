import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { init as initContentfulExtension } from 'contentful-ui-extensions-sdk';
import { ColorPicker } from './components/ColorPicker';

const ExtensionComponent = () => {
	const [val, updateVal] = useState('rgba(0, 0, 0, 1)');
	const logColor = nextColor => {
		console.log('nextColor', nextColor);
	};
	const [updateFunc, updateTheUpdateFunc] = useState(() => logColor);

	useEffect(() => {
		initContentfulExtension(extension => {
			extension.window.startAutoResizer();

			const startVal = extension.field.getValue();
			updateVal(startVal);

			const updateIt = nextColor => {
				extension.field.setValue(nextColor);
			};
			/* 
				If you don't wrap this with another function call, it treats it
				like the (prevState) => {return nextState} function for setState

				See the following:
				https://reactjs.org/docs/hooks-reference.html#functional-updates
				"If the new state is computed using the previous state, you can
				pass a function to setState.""
			*/
			updateTheUpdateFunc(() => updateIt);
		});
	}, []);
	return <ColorPicker value={val} updateValue={updateFunc} />;
};

ReactDOM.render(<ExtensionComponent />, document.getElementById('app'));
