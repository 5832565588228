import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { palette } from '@heb-engineering/palette/lib/palette';

const buildColor = colorPickerRaw => {
	const { r, g, b, a } = colorPickerRaw.rgb;
	return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const ColorPicker = ({ value, updateValue }) => {
	const updateColor = colorPickerRaw => {
		const nextColor = buildColor(colorPickerRaw);
		updateValue(nextColor);
	};

	return (
		<SketchPicker
			color={value}
			onChangeComplete={updateColor}
			presetColors={Object.keys(palette).map(colorKey => ({
				color: palette[colorKey],
				title: colorKey,
			}))}
		/>
	);
};
